export const newExamGradeColCommonPart = [
  {
    dataIndex: "eleccateUnionRank",
    title: "整体选考类别排名",
    align: "center",
    width: 75,
  },
  {
    dataIndex: "eleccombUnionRank",
    title: "整体选考组合排名",
    align: "center",
    width: 75,
  },
  {
    dataIndex: "unionRank",
    title: "整体排名",
    width: 75,
    align: "center",
  },
  {
    dataIndex: "eleccateSchoolRank",
    title: "校内选考类别排名",
    width: 75,
    align: "center",
  },
  {
    dataIndex: "eleccombSchoolRank",
    title: "校内选考组合排名",
    align: "center",
    width: 75,
  },
  {
    dataIndex: "schoolRank",
    title: "校内排名",
    width: 75,
    align: "center",
  },
];
