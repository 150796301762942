<template>
  <div class="container">
    <div class="operate">
      <div class="title-content">
        <div class="title">
          {{ $route.query.name }}-{{ subjectId === 0 ? "总分" : subjectName
          }}{{ type == "2" ? "详细" : "" }}成绩榜
        </div>
        <a-tooltip placement="top">
          <template slot="title">
            <span>采用{{ dataType === 2 ? "赋分" : "原始分" }}数据源统计</span>
          </template>
          <a-radio-group
            v-if="
              scoreType == 1 &&
              $route.query.ruleId > 0 &&
              ![1, 2, 3, 4, 8].includes(subjectId)
            "
            v-model="dataType"
            button-style="solid"
            size="small"
            @change="dataTypeChange"
          >
            <a-radio-button :value="1"> 原始分 </a-radio-button>
            <a-radio-button :value="2"> 赋分 </a-radio-button>
          </a-radio-group>
        </a-tooltip>
      </div>
      <div class="btns">
        <a-space>
          <a-input
            v-model="search.input"
            placeholder="搜索考号或姓名"
            @input="getData"
          ></a-input>
          <a-button
            v-if="type === '1'"
            type="primary"
            ghost
            :loading="btnLoading"
            @click="downloadFile"
            >下载{{ subjectName }}成绩榜</a-button
          >
          <a-button
            v-if="type === '2'"
            type="primary"
            ghost
            :loading="btnLoading"
            @click="downloadFile"
            >下载{{ subjectName }}详细成绩榜</a-button
          >
          <a-button type="primary" ghost @click="exportSheetCard"
            >导出答题卡</a-button
          >
        </a-space>
      </div>
    </div>
    <subjectList
      ref="subject"
      v-model="subjectId"
      has-total
      @change="subjectChange"
    />
    <a-tabs v-model="type" type="card" @change="tabsChange">
      <a-tab-pane key="1" tab="成绩榜"> </a-tab-pane>
      <a-tab-pane key="2" tab="详细成绩榜"> </a-tab-pane>
    </a-tabs>
    <a-table
      bordered
      :row-key="(record) => record.id"
      :columns="columns"
      :data-source="tableData"
      :pagination="{
        total: total,
        pageSize: search.size,
        current: search.current,
        showSizeChanger: true,
        showTotal: (total) => `共 ${total}条`,
      }"
      :scroll="{ x: 'max-content', y: tableX }"
      @change="tablePaginationChange"
    ></a-table>
    <a-modal
      v-model="visible"
      :title="`导出${subjectName}答题卡`"
      width="35%"
      :footer="showProgress ? null : undefined"
      :ok-text="progressNum >= 100 ? '重新生成并导出' : '生成并导出'"
      @ok="handleOk"
      @cancle="handleCancle"
    >
      <div class="row">
        分数
        <a-input
          v-model.number="score"
          :disabled="showProgress"
          style="width: 80px"
        ></a-input>
      </div>
      <a-radio-group v-model="scoreTypes" :disabled="showProgress">
        <a-radio :value="1"> 导出低分答题卡（小于等于≤，>0） </a-radio>
        <a-radio :value="2"> 导出高分答题卡（大于>） </a-radio>
      </a-radio-group>
      <div v-if="progressNum > -1" class="progress">
        <a-progress
          stroke-linecap="square"
          stroke-color="#2474ED"
          :percent="progressNum"
        />
        <div v-if="progressNum < 100" class="text">
          数据生成中，请耐心等待，或稍后回到此页面进行导出操作。
        </div>
        <div v-else class="text">
          数据已生成，若导出尚未启动，可点击此处
          <a-button type="link" :loading="btnLoading" @click="downloadCard"
            >手动下载</a-button
          >
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import subjectList from "../../../components/subjectList.vue";
import { mapState } from "vuex";
import { getstatinfo } from "@/core/api/academic/common";
import {
  studentlisttotal,
  studentlisttotaldetails,
  studentlistsubject,
  studentlistsubjectdetails,
  studentlisttotalanswersheetExport,
} from "@/core/api/academic/unionSheet";
import {
  studentlisttotalExport,
  studentlisttotaldetailsExport,
  studentlistsubjectExport,
  studentlistsubjectdetailsExport,
} from "@/core/api/academic/unionSheetExport";
import { filterSubject } from "@/core/util/util";
import { newExamGradeColCommonPart } from "./cols";
export default {
  name: "",
  components: {
    subjectList,
  },
  data() {
    return {
      showProgress: false,
      progressNum: -1,
      currentFlag: false,
      timer: null,
      tableData: [],
      type: "1",
      dataType: 1,
      columns: [],
      subjectId: 1,
      total: 0,
      tableX: window.innerHeight - 310,
      btnLoading: false,
      search: {
        size: 20,
        current: 1,
        input: undefined,
      },
      isSearch: 1,
      subjectName: "",
      statInfo: null,
      subjectList: filterSubject(),
      visible: false,
      score: null,
      scoreTypes: 1,
      fistTime: false,
    };
  },
  computed: {
    ...mapState({
      scoreType: (state) => state.report.scoreType,
    }),
  },
  mounted() {
    this.getstatinfoData();
  },
  methods: {
    clearTimer() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
    },
    async getProgress() {
      try {
        const res = await studentlisttotalanswersheetExport({
          statId: this.$route.query.id,
          subjectId: this.subjectId,
          scoreType: this.scoreTypes,
          score: this.score,
          isSearch: this.isSearch,
        });
        this.progressNum = res.data.data.progress;
        this.isSearch = 1;
        if (this.progressNum >= 100) {
          this.clearTimer();
          this.showProgress = false;
          if (this.currentFlag) {
            this.downloadCard();
          }
        } else if (this.fistTime) {
          this.fistTime = false;
          if (this.progressNum > -1) {
            this.clearTimer();
            this.continueGet();
          }
        } else {
          this.clearTimer();
          this.continueGet();
        }
      } catch {
        this.showProgress = false;
      }
    },
    async handleOk() {
      if (typeof this.score !== "number") {
        this.$message({
          message: "请输入分数",
          showClose: true,
          type: "warning",
        });
        return false;
      }
      if (this.progressNum == 100) {
        this.progressNum = -1;
      }
      this.isSearch = 0;
      this.currentFlag = true;
      this.firstTime = false;
      this.getProgress();
      this.progressNum = -1;
      this.showProgress = true;
    },
    async downloadCard() {
      this.isSearch = 1;
      const res = await studentlisttotalanswersheetExport({
        statId: this.$route.query.id,
        subjectId: this.subjectId,
        scoreType: this.scoreTypes,
        score: this.score,
        isSearch: this.isSearch,
      });
      this.currentFlag = false;
      const { href } = this.$router.resolve({
        path: "/downFile",
        query: {
          url: res.data.data.url,
          name: res.data.data.name,
          type: 1,
        },
      });
      let timer = setTimeout(() => {
        window.open(href, "_blank");
        clearTimeout(timer);
      }, 0);
    },
    continueGet() {
      if (this.progressNum < 100) {
        this.currentFlag = true;
        this.isSearch = 1;
        if (!this.timer) {
          this.timer = setTimeout(() => {
            this.getProgress();
          }, 1500);
        }
      }
    },
    handleCancle() {
      this.visible = false;
    },
    exportSheetCard() {
      this.visible = true;
      this.isSearch = 1;
      this.fistTime = true;
      this.getProgress();
    },
    async getstatinfoData() {
      const res = await getstatinfo({ id: this.$route.query.id });
      this.statInfo = res.data.data.stat;
      this.getTotalKey1Data();
    },
    async downloadFile() {
      let res;
      try {
        this.btnLoading = true;
        if (this.type === "1") {
          if (this.subjectId == "0") {
            res = await studentlisttotalExport({
              statId: this.$route.query.id,
              subjectId: this.subjectId,
              dataSource:
                this.scoreType == 1 && this.$route.query.ruleId > 0
                  ? this.dataType
                  : 1,
              ...this.search,
            });
          } else {
            res = await studentlistsubjectExport({
              statId: this.$route.query.id,
              subjectId: this.subjectId,
              dataSource:
                this.scoreType == 1 && this.$route.query.ruleId > 0
                  ? this.dataType
                  : 1,
              ...this.search,
            });
          }
        } else if (this.type === "2") {
          if (this.subjectId == 0) {
            res = await studentlisttotaldetailsExport({
              statId: this.$route.query.id,
              subjectId: this.subjectId,
              dataSource:
                this.scoreType == 1 && this.$route.query.ruleId > 0
                  ? this.dataType
                  : 1,
              ...this.search,
            });
          } else {
            res = await studentlistsubjectdetailsExport({
              statId: this.$route.query.id,
              subjectId: this.subjectId,
              dataSource:
                this.scoreType == 1 && this.$route.query.ruleId > 0
                  ? this.dataType
                  : 1,
              ...this.search,
            });
          }
        }
        const { name, url } = res.data.data;
        const { href } = this.$router.resolve({
          path: "/downFile",
          query: {
            url: url,
            name: name,
            type: 1,
          },
        });
        let timer = setTimeout(() => {
          window.open(href, "_blank");
          clearTimeout(timer);
        }, 0);
        this.btnLoading = false;
      } catch {
        this.btnLoading = false;
      }
    },
    subjectChange() {
      if ([1, 2, 3, 4, 8].includes(this.subjectId)) {
        this.dataType = 1;
      }
      if (this.subjectId == 0) {
        this.subjectName = "总分";
      } else {
        let subject = this.$refs.subject.subjectList.find(
          (item) => item.id == this.subjectId
        );
        this.subjectName = subject.name;
      }
      this.tabsChange();
    },
    tablePaginationChange(val) {
      if (this.search.size !== val.pageSize) {
        this.search.current = 1;
        this.search.size = val.pageSize;
      } else {
        this.search.current = val.current;
      }
      this.getData();
    },
    async getTotalKey1Data() {
      try {
        this.loading = true;
        const res = await studentlisttotal({
          statId: this.$route.query.id,
          subjectId: this.subjectId,
          dataSource:
            this.scoreType == 1 && this.$route.query.ruleId > 0
              ? this.dataType
              : 1,
          ...this.search,
        });
        this.total = res.data.data.total;
        let cols = [];
        if (res.data.data.records.length) {
          cols = res.data.data.records[0].subjects;
        }
        let isNewExam =
          this.$route.query.scoreType == 1 && this.$route.query.ruleId > 0;
        if (isNewExam) {
          // let isAp = cols.filter((item) => item.isAp === 1);
          // let notAp = cols.filter((item) => item.isAp === 0);

          this.columns = [
            ...newExamGradeColCommonPart,
            {
              dataIndex: "schoolName",
              width: 160,

              title: "学校",
              align: "center",
            },

            {
              dataIndex: "studentNo",
              title: "学籍号",
              width: 160,
              align: "center",
            },
            {
              dataIndex: "studentExamId",
              title: "考号",
              width: 160,
              align: "center",
            },
            {
              dataIndex: "classNum",
              title: "行政班级",
              width: 100,
              align: "center",
            },
            {
              dataIndex: "studentName",
              title: "姓名",
              width: 75,

              align: "center",
            },
            {
              dataIndex: "eleccomb",
              title: "选考组合",
              width: 75,
              align: "center",
            },
            this.dataType == 2
              ? {
                  dataIndex: "totalScore",
                  title: "总分",
                  align: "center",
                  children: [
                    {
                      dataIndex: "scoreOri",
                      title: "原始分",
                      width: 85,
                      align: "center",
                    },
                    {
                      dataIndex: "score",
                      title: "赋分",
                      width: 85,
                      align: "center",
                    },
                  ],
                }
              : {
                  dataIndex: "scoreOri",
                  title: "总分",
                  width: 85,
                  align: "center",
                },

            ...cols.map((item, index) => {
              if (item.isAp === 1) {
                return {
                  dataIndex: "apSubject" + index,
                  title: item.subjectName,
                  align: "center",
                  children: [
                    {
                      dataIndex: "apscoreOri" + index,
                      title: "原始分",
                      width: 85,
                      align: "center",
                      customRender: (text, record) => {
                        let subject = record.subjects.find(
                          (it) => it.subjectId === item.subjectId
                        );
                        if (subject.answerSheetUrl) {
                          return (
                            <span
                              vOn:click={() =>
                                this.scoreClick(
                                  subject.subjectId,
                                  record,
                                  subject.answerSheetUrl
                                )
                              }
                              class="pointScore"
                            >
                              {subject.scoreOri}
                            </span>
                          );
                        } else {
                          return subject.scoreOri;
                        }
                      },
                    },
                    {
                      dataIndex: "apscore" + index,
                      title: "赋分",
                      align: "center",
                      width: 85,
                      customRender: (text, record) => {
                        let subject = record.subjects.find(
                          (it) => it.subjectId === item.subjectId
                        );
                        return subject.score;
                      },
                    },
                    {
                      dataIndex: "aplevel" + index,
                      title: "等级",
                      align: "center",
                      width: 85,
                      customRender: (text, record) => {
                        let subject = record.subjects.find(
                          (it) => it.subjectId === item.subjectId
                        );
                        return subject.level;
                      },
                    },
                  ],
                };
              } else {
                return {
                  dataIndex: "subject" + index,
                  title: item.subjectName,
                  align: "center",
                  width: 85,
                  // customRender: (text, record) => {
                  //   let subject = record.subjects.find(
                  //     (it) => it.subjectId === item.subjectId
                  //   );
                  //   return subject.scoreOri;
                  // },

                  // 2024/04/29 冯参说所有科目有答题卡都跳转至查看答题卡
                  customRender: (text, record) => {
                    let subject = record.subjects.find(
                      (it) => it.subjectId === item.subjectId
                    );
                    if (subject.answerSheetUrl) {
                      return (
                        <span
                          vOn:click={() =>
                            this.scoreClick(
                              subject.subjectId,
                              record,
                              subject.answerSheetUrl
                            )
                          }
                          class="pointScore"
                        >
                          {subject.scoreOri}
                        </span>
                      );
                    } else {
                      return subject.scoreOri;
                    }
                  },
                };
              }
            }),
          ];
        } else {
          this.columns = [
            {
              dataIndex: "schoolName",
              width: 160,
              title: "学校",
              align: "center",
            },
            {
              dataIndex: "studentNo",
              title: "学籍号",
              width: 160,
              align: "center",
            },
            {
              dataIndex: "studentExamId",
              title: "考号",
              width: 160,
              align: "center",
            },
            {
              dataIndex: "classNum",
              width: 100,

              title: "行政班级",
              align: "center",
            },
            {
              dataIndex: "studentName",
              width: 120,
              title: "姓名",
              align: "center",
            },
            {
              dataIndex: "scoreOri",
              title: "总分",
              align: "center",
              width: 85,
            },
            ...cols.map((item, index) => {
              return {
                dataIndex: "subject" + index,
                title: item.subjectName,
                align: "center",
                width: 85,

                customRender: (text, record) => {
                  let subject = record.subjects.find(
                    (it) => it.subjectId === item.subjectId
                  );
                  if (subject.answerSheetUrl) {
                    return (
                      <span
                        vOn:click={() =>
                          this.scoreClick(
                            subject.subjectId,
                            record,
                            subject.answerSheetUrl
                          )
                        }
                        class="pointScore"
                      >
                        {subject.scoreOri}
                      </span>
                    );
                  } else {
                    return subject.scoreOri;
                  }
                },
              };
            }),
            {
              dataIndex: "studentType",
              title: "分类",
              align: "center",
              width: 85,
            },
            {
              dataIndex: "categoryUnionRank",
              title: "整体分类排名",
              align: "center",
              width: 110,
            },
            {
              dataIndex: "categorySchoolRank",
              title: "校内分类排名",
              align: "center",
              width: 110,
            },
          ];
          if (this.statInfo.isUnionDisplayStudentRank == 1) {
            this.columns.splice(
              0,
              0,
              {
                dataIndex: "unionRank",
                title: "整体排名",
                width: 100,
                align: "center",
              },
              {
                dataIndex: "schoolRank",
                title: "校内排名",
                width: 100,

                align: "center",
              }
            );
          }
        }
        this.tableData = res.data.data.records;
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    async getTotalDetailKey1Data() {
      try {
        this.loading = true;
        const res = await studentlisttotaldetails({
          statId: this.$route.query.id,
          subjectId: this.subjectId,
          dataSource:
            this.scoreType == 1 && this.$route.query.ruleId > 0
              ? this.dataType
              : 1,
          ...this.search,
        });
        let cols = [];
        if (res.data.data.records.length) {
          cols = res.data.data.records[0].subjects;
        }
        let isNewExam =
          this.$route.query.scoreType == 1 && this.$route.query.ruleId > 0;
        if (isNewExam) {
          this.columns = [
            ...newExamGradeColCommonPart,
            {
              dataIndex: "schoolName",
              title: "学校",
              width: 100,
              align: "center",
            },
            {
              dataIndex: "studentNo",
              title: "学籍号",
              width: 160,
              align: "center",
            },
            {
              dataIndex: "studentExamId",

              width: 160,
              title: "考号",
              align: "center",
            },
            {
              dataIndex: "classNum",
              title: "班级",
              width: 100,
              align: "center",
            },
            {
              dataIndex: "studentName",
              title: "姓名",
              width: 120,
              align: "center",
            },
            {
              dataIndex: "eleccomb",
              title: "选考组合",
              width: 90,
              align: "center",
            },
            this.dataType == 2
              ? {
                  dataIndex: "totalScore",
                  title: "总分",
                  align: "center",
                  children: [
                    {
                      dataIndex: "scoreOri",
                      title: "原始分",
                      width: 85,
                      align: "center",
                    },
                    {
                      dataIndex: "score",
                      title: "赋分",
                      width: 85,
                      align: "center",
                    },
                  ],
                }
              : {
                  dataIndex: "scoreOri",
                  title: "总分",
                  width: 85,
                  align: "center",
                },
            ...cols.map((item, index) => {
              if (item.subjectId != 0) {
                if ([1, 2, 3, 4, 8].includes(item.subjectId)) {
                  return {
                    dataIndex: "subject" + index,
                    title: item.subjectName,
                    align: "center",
                    width: 120,
                    children: [
                      {
                        title: this.dataType == 2 ? "原始分" : "得分",
                        align: "center",
                        dataIndex: "totalScoreOri" + index,
                        width: 75,

                        customRender: (text, record) => {
                          let subject = record.subjects.find(
                            (it) => it.subjectId === item.subjectId
                          );
                          if (subject.answerSheetUrl) {
                            return (
                              <span
                                vOn:click={() =>
                                  this.scoreClick(
                                    subject.subjectId,
                                    record,
                                    subject.answerSheetUrl
                                  )
                                }
                                class="pointScore"
                              >
                                {subject.scoreOri}
                              </span>
                            );
                          } else {
                            return subject.scoreOri;
                          }
                        },
                      },
                      {
                        title: "校内选考类别排名",
                        align: "center",
                        width: 75,
                        dataIndex: "eleccateSchoolRank" + index,

                        customRender: (text, record) => {
                          let subject = record.subjects.find(
                            (it) => it.subjectId === item.subjectId
                          );
                          return subject.eleccateSchoolRank;
                        },
                      },
                      {
                        title: "校内选考组合排名",
                        align: "center",
                        dataIndex: "eleccombSchoolRank" + index,
                        width: 75,

                        customRender: (text, record) => {
                          let subject = record.subjects.find(
                            (it) => it.subjectId === item.subjectId
                          );
                          return subject.eleccombSchoolRank;
                        },
                      },
                      {
                        title: "校内排名",
                        align: "center",
                        dataIndex: "schoolRank" + index,
                        width: 75,

                        customRender: (text, record) => {
                          let subject = record.subjects.find(
                            (it) => it.subjectId === item.subjectId
                          );
                          return subject.schoolRank;
                        },
                      },
                      {
                        title: "整体选考类别排名",
                        align: "center",
                        width: 75,
                        dataIndex: "eleccateUnionRank" + index,

                        customRender: (text, record) => {
                          let subject = record.subjects.find(
                            (it) => it.subjectId === item.subjectId
                          );
                          return subject.eleccateUnionRank;
                        },
                      },
                      {
                        title: "整体选考组合排名",
                        align: "center",
                        width: 75,
                        dataIndex: "eleccombUnionRank" + index,

                        customRender: (text, record) => {
                          let subject = record.subjects.find(
                            (it) => it.subjectId === item.subjectId
                          );
                          return subject.eleccombUnionRank;
                        },
                      },
                      {
                        title: "整体排名",
                        align: "center",
                        width: 75,
                        dataIndex: "unionRank" + index,

                        customRender: (text, record) => {
                          let subject = record.subjects.find(
                            (it) => it.subjectId === item.subjectId
                          );
                          return subject.unionRank;
                        },
                      },
                    ],
                  };
                } else {
                  let subjectApChilds = [];
                  if (this.dataType == 2) {
                    subjectApChilds = [
                      {
                        title: "赋分",
                        align: "center",
                        dataIndex: "totalScore" + index,
                        width: 75,

                        customRender: (text, record) => {
                          let subject = record.subjects.find(
                            (it) => it.subjectId === item.subjectId
                          );
                          return subject.score;
                        },
                      },
                      {
                        title: "等级",
                        align: "center",
                        dataIndex: "level" + index,
                        width: 75,
                        customRender: (text, record) => {
                          let subject = record.subjects.find(
                            (it) => it.subjectId === item.subjectId
                          );
                          return subject.level;
                        },
                      },
                    ];
                  }
                  return {
                    dataIndex: "subject" + index,
                    title: item.subjectName,
                    align: "center",
                    width: 120,
                    children: [
                      {
                        title: this.dataType == 2 ? "原始分" : "得分",
                        align: "center",
                        dataIndex: "totalScoreOri" + index,
                        width: 75,

                        customRender: (text, record) => {
                          let subject = record.subjects.find(
                            (it) => it.subjectId === item.subjectId
                          );
                          if (subject.answerSheetUrl) {
                            return (
                              <span
                                vOn:click={() =>
                                  this.scoreClick(
                                    subject.subjectId,
                                    record,
                                    subject.answerSheetUrl
                                  )
                                }
                                class="pointScore"
                              >
                                {subject.scoreOri}
                              </span>
                            );
                          } else {
                            return subject.scoreOri;
                          }
                        },
                      },
                      ...subjectApChilds,
                      {
                        title: "校内选考类别排名",
                        align: "center",
                        width: 75,
                        dataIndex: "eleccateSchoolRank" + index,

                        customRender: (text, record) => {
                          let subject = record.subjects.find(
                            (it) => it.subjectId === item.subjectId
                          );
                          return subject.eleccateSchoolRank;
                        },
                      },
                      {
                        title: "校内选考组合排名",
                        align: "center",
                        dataIndex: "eleccombSchoolRank" + index,
                        width: 75,

                        customRender: (text, record) => {
                          let subject = record.subjects.find(
                            (it) => it.subjectId === item.subjectId
                          );
                          return subject.eleccombSchoolRank;
                        },
                      },
                      {
                        title: "校内排名",
                        align: "center",
                        dataIndex: "schoolRank" + index,
                        width: 75,

                        customRender: (text, record) => {
                          let subject = record.subjects.find(
                            (it) => it.subjectId === item.subjectId
                          );
                          return subject.schoolRank;
                        },
                      },
                      {
                        title: "整体选考类别排名",
                        align: "center",
                        width: 75,
                        dataIndex: "eleccateUnionRank" + index,

                        customRender: (text, record) => {
                          let subject = record.subjects.find(
                            (it) => it.subjectId === item.subjectId
                          );
                          return subject.eleccateUnionRank;
                        },
                      },
                      {
                        title: "整体选考组合排名",
                        align: "center",
                        width: 75,
                        dataIndex: "eleccombUnionRank" + index,

                        customRender: (text, record) => {
                          let subject = record.subjects.find(
                            (it) => it.subjectId === item.subjectId
                          );
                          return subject.eleccombUnionRank;
                        },
                      },
                      {
                        title: "整体排名",
                        align: "center",
                        width: 75,
                        dataIndex: "unionRank" + index,

                        customRender: (text, record) => {
                          let subject = record.subjects.find(
                            (it) => it.subjectId === item.subjectId
                          );
                          return subject.unionRank;
                        },
                      },
                    ],
                  };
                }
              } else {
                return {
                  dataIndex: "subject" + index,
                  title: item.subjectName,
                  align: "center",
                  children: [
                    {
                      title: "原始分",
                      align: "center",
                      dataIndex: "totalScoreOri",
                      width: 75,

                      customRender: (text, record) => {
                        let subject = record.subjects.find(
                          (it) => it.subjectId === item.subjectId
                        );
                        if (subject.answerSheetUrl) {
                          return (
                            <span
                              vOn:click={() =>
                                this.scoreClick(
                                  subject.subjectId,
                                  record,
                                  subject.answerSheetUrl
                                )
                              }
                              class="pointScore"
                            >
                              {subject.scoreOri}
                            </span>
                          );
                        } else {
                          return subject.scoreOri;
                        }
                      },
                    },
                    {
                      title: "赋分",
                      align: "center",
                      width: 75,
                      dataIndex: "totalScore",

                      customRender: (text, record) => {
                        let subject = record.subjects.find(
                          (it) => it.subjectId === item.subjectId
                        );
                        return subject.score;
                      },
                    },
                  ],
                };
              }
            }),
          ];
        } else {
          this.columns = [
            {
              dataIndex: "schoolName",
              title: "学校",
              width: 155,

              align: "center",
            },
            {
              dataIndex: "studentNo",
              title: "学籍号",
              width: 160,
              align: "center",
            },
            {
              dataIndex: "studentExamId",
              title: "考号",
              width: 160,

              align: "center",
            },
            {
              dataIndex: "classNum",
              title: "行政班级",
              width: 75,

              align: "center",
            },
            {
              dataIndex: "studentName",
              title: "姓名",
              width: 90,
              align: "center",
            },

            {
              dataIndex: "scoreOri",
              title: "总分",
              width: 50,
              align: "center",
            },
            ...cols.map((item, index) => {
              if (item.subjectId === 0) {
                return {
                  dataIndex: "subject" + index,
                  title: item.subjectName,
                  width: 100,
                  align: "center",
                  children: [
                    {
                      title: "得分",
                      align: "center",
                      dataIndex: "scoreOri" + index,
                      width: 60,

                      customRender: (text, record) => {
                        let subject = record.subjects.find(
                          (it) => it.subjectId === item.subjectId
                        );
                        if (subject.answerSheetUrl) {
                          return (
                            <span
                              vOn:click={() =>
                                this.scoreClick(
                                  subject.subjectId,
                                  record,
                                  subject.answerSheetUrl
                                )
                              }
                              class="pointScore"
                            >
                              {subject.scoreOri}
                            </span>
                          );
                        } else {
                          return subject.scoreOri;
                        }
                      },
                    },
                  ],
                };
              } else {
                return {
                  dataIndex: "subject" + index,
                  title: item.subjectName,
                  align: "center",
                  children: [
                    {
                      title: "校内排名",
                      align: "center",
                      dataIndex: "schoolrank" + index,
                      width: 75,

                      customRender: (text, record) => {
                        let subject = record.subjects.find(
                          (it) => it.subjectId === item.subjectId
                        );
                        return subject.schoolRank;
                      },
                    },
                    {
                      title: "整体排名",
                      align: "center",
                      dataIndex: "unionrank" + index,
                      width: 75,

                      customRender: (text, record) => {
                        let subject = record.subjects.find(
                          (it) => it.subjectId === item.subjectId
                        );
                        return subject.unionRank;
                      },
                    },
                    {
                      title: "得分",
                      align: "center",
                      dataIndex: "scoreOri" + index,
                      width: 50,

                      customRender: (text, record) => {
                        let subject = record.subjects.find(
                          (it) => it.subjectId === item.subjectId
                        );
                        if (subject.answerSheetUrl) {
                          return (
                            <span
                              vOn:click={() =>
                                this.scoreClick(
                                  subject.subjectId,
                                  record,
                                  subject.answerSheetUrl
                                )
                              }
                              class="pointScore"
                            >
                              {subject.scoreOri}
                            </span>
                          );
                        } else {
                          return subject.scoreOri;
                        }
                      },
                    },
                  ],
                };
              }
            }),
            {
              dataIndex: "studentType",
              title: "分类",
              align: "center",
              width: 85,
            },
            {
              dataIndex: "categoryUnionRank",
              title: "整体分类排名",
              align: "center",
              width: 110,
            },
            {
              dataIndex: "categorySchoolRank",
              title: "校内分类排名",
              align: "center",
              width: 110,
            },
          ];
          if (this.statInfo.isUnionDisplayStudentRank == 1) {
            this.columns.splice(
              0,
              0,
              {
                dataIndex: "unionRank",
                title: "整体排名",
                width: 75,
                align: "center",
              },
              {
                dataIndex: "schoolRank",
                title: "校内排名",
                width: 75,

                align: "center",
              }
            );
          }
        }
        this.tableData = res.data.data.records;
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    async getSubjectKey1Data() {
      try {
        this.loading = true;
        const res = await studentlistsubject({
          ...this.search,
          statId: this.$route.query.id,
          subjectId: this.subjectId,
          dataSource:
            this.scoreType == 1 && this.$route.query.ruleId > 0
              ? this.dataType
              : 1,
        });
        let isNewExam =
          this.$route.query.scoreType == 1 && this.$route.query.ruleId > 0;
        if (isNewExam) {
          let apCols = [];
          if (this.dataType == 2) {
            apCols = [
              {
                dataIndex: "score",
                title: "赋分",
                width: 85,

                align: "center",
              },
              {
                dataIndex: "level",
                title: "等级",
                width: 85,

                align: "center",
              },
            ];
          }
          this.columns = [
            ...newExamGradeColCommonPart,
            {
              dataIndex: "schoolName",
              title: "学校",
              width: 140,
              align: "center",
            },
            {
              dataIndex: "studentNo",
              title: "学籍号",
              width: 110,
              align: "center",
            },
            {
              dataIndex: "studentExamId",

              width: 160,
              title: "考号",
              align: "center",
            },
            {
              dataIndex: "classNum",
              title: "教学班级",
              width: 75,

              align: "center",
            },
            {
              dataIndex: "studentName",
              title: "姓名",
              width: 90,

              align: "center",
            },
            {
              dataIndex: "eleccomb",
              title: "选考组合",
              width: 75,

              align: "center",
            },
            {
              dataIndex: "scoreOri",
              width: 60,
              title: this.dataType == 2 ? "原始分" : "得分",

              align: "center",
              customRender: (text, record) => {
                if (record.answerSheetUrl) {
                  return (
                    <span
                      vOn:click={() =>
                        this.scoreClick(
                          this.subjectId,
                          record,

                          record.answerSheetUrl
                        )
                      }
                      class="pointScore"
                    >
                      {record.scoreOri}
                    </span>
                  );
                } else {
                  return record.scoreOri;
                }
              },
            },
            ...apCols,
            {
              dataIndex: "objectiveScore",
              width: 60,
              title: "客观题",

              align: "center",
            },
            {
              dataIndex: "subjectiveScore",
              title: "主观题",
              width: 60,

              align: "center",
            },
            {
              dataIndex: "note",
              title: "备注",
              width: 75,

              align: "center",
            },
          ];
        } else {
          this.columns = [
            {
              dataIndex: "schoolName",
              title: "学校",
              width: 140,

              align: "center",
            },
            {
              dataIndex: "studentNo",
              title: "学籍号",
              width: 110,
              align: "center",
            },
            {
              dataIndex: "studentExamId",

              width: 160,
              title: "考号",
              align: "center",
            },
            {
              dataIndex: "classNum",
              title: "教学班级",
              width: 75,

              align: "center",
            },
            {
              dataIndex: "studentName",
              title: "姓名",
              width: 90,

              align: "center",
            },
            {
              dataIndex: "scoreOri",
              title: "得分",
              width: 60,

              align: "center",
              customRender: (text, record) => {
                if (record.answerSheetUrl) {
                  return (
                    <span
                      vOn:click={() =>
                        this.scoreClick(
                          this.subjectId,
                          record,
                          record.answerSheetUrl
                        )
                      }
                      class="pointScore"
                    >
                      {record.scoreOri}
                    </span>
                  );
                } else {
                  return record.scoreOri;
                }
              },
            },
            {
              dataIndex: "objectiveScore",
              title: "客观题",
              width: 60,

              align: "center",
            },
            {
              dataIndex: "subjectiveScore",
              title: "主观题",
              width: 60,

              align: "center",
            },
            {
              dataIndex: "studentType",
              title: "分类",
              align: "center",
              width: 85,
            },
            {
              dataIndex: "categoryUnionRank",
              title: "整体分类排名",
              align: "center",
              width: 110,
            },
            {
              dataIndex: "categorySchoolRank",
              title: "校内分类排名",
              align: "center",
              width: 110,
            },
            {
              dataIndex: "note",
              title: "备注",
              width: 75,

              align: "center",
            },
          ];
          if (this.statInfo.isUnionDisplayStudentRank == 1) {
            this.columns.splice(
              0,
              0,
              {
                dataIndex: "unionRank",
                title: "整体排名",
                width: 75,
                align: "center",
              },
              {
                dataIndex: "schoolRank",
                title: "校内排名",
                width: 75,

                align: "center",
              }
            );
          }
        }
        this.tableData = res.data.data.records;
        this.total = res.data.data.total;
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    async getSubjectDetailKey1Data() {
      try {
        this.loading = true;
        const res = await studentlistsubjectdetails({
          ...this.search,
          statId: this.$route.query.id,
          subjectId: this.subjectId,
          dataSource:
            this.scoreType == 1 && this.$route.query.ruleId > 0
              ? this.dataType
              : 1,
        });
        let questions = [];
        if (res.data.data.records.length) {
          questions = res.data.data.records[0].questions;
        }
        let isNewExam =
          this.$route.query.scoreType == 1 && this.$route.query.ruleId > 0;
        if (isNewExam) {
          let apCols = [];
          if (this.dataType == 2) {
            apCols = [
              {
                dataIndex: "score",
                title: "赋分",
                width: 85,

                align: "center",
              },
              {
                dataIndex: "level",
                title: "等级",
                width: 85,

                align: "center",
              },
            ];
          }
          this.columns = [
            ...newExamGradeColCommonPart,
            {
              dataIndex: "schoolName",
              title: "学校",
              width: 180,

              align: "center",
            },
            {
              dataIndex: "studentNo",
              title: "学籍号",
              width: 160,
              align: "center",
            },
            {
              dataIndex: "studentExamId",
              width: 160,

              title: "考号",
              align: "center",
            },
            {
              dataIndex: "classNum",
              title: "教学班级",
              width: 100,

              align: "center",
            },
            {
              dataIndex: "studentName",
              title: "姓名",
              width: 120,

              align: "center",
            },
            {
              dataIndex: "eleccomb",
              title: "选考组合",
              width: 100,

              align: "center",
            },
            {
              dataIndex: "scoreOri",
              title: this.dataType == 2 ? "原始分" : "得分",
              width: 85,

              align: "center",
              customRender: (text, record) => {
                if (record.answerSheetUrl) {
                  return (
                    <span
                      vOn:click={() =>
                        this.scoreClick(
                          this.subjectId,
                          record,
                          record.answerSheetUrl
                        )
                      }
                      class="pointScore"
                    >
                      {record.scoreOri}
                    </span>
                  );
                } else {
                  return record.scoreOri;
                }
              },
            },
            ...apCols,
            {
              dataIndex: "objectiveScore",
              title: "客观题",
              width: 85,

              align: "center",
            },
            {
              dataIndex: "subjectiveScore",
              title: "主观题",
              width: 85,

              align: "center",
            },

            ...questions.map((item, index) => ({
              dataIndex: "questions" + index,
              title: item.questionOrder,

              width: 100,
              align: "center",
              customRender: (text, record) =>
                record.questions[index].actualScore || "",
            })),
            {
              dataIndex: "note",
              title: "备注",
              width: 100,

              align: "center",
            },
          ];
        } else {
          this.columns = [
            {
              dataIndex: "schoolName",
              title: "学校",
              width: 155,

              align: "center",
            },
            {
              dataIndex: "studentNo",
              title: "学籍号",
              width: 110,
              align: "center",
            },
            {
              dataIndex: "studentExamId",
              width: 160,

              title: "考号",
              align: "center",
            },
            {
              dataIndex: "classNum",
              title: "教学班级",
              width: 75,

              align: "center",
            },
            {
              dataIndex: "studentName",
              title: "姓名",
              width: 90,

              align: "center",
            },
            {
              dataIndex: "scoreOri",
              title: "得分",
              width: 60,

              align: "center",
              customRender: (text, record) => {
                if (record.answerSheetUrl) {
                  return (
                    <span
                      vOn:click={() =>
                        this.scoreClick(
                          this.subjectId,
                          record,
                          record.answerSheetUrl
                        )
                      }
                      class="pointScore"
                    >
                      {record.scoreOri}
                    </span>
                  );
                } else {
                  return record.scoreOri;
                }
              },
            },
            {
              dataIndex: "objectiveScore",
              title: "客观题",
              width: 60,

              align: "center",
            },
            {
              dataIndex: "subjectiveScore",
              title: "主观题",
              width: 60,

              align: "center",
            },

            ...questions.map((item, index) => ({
              dataIndex: "questions" + index,
              title: item.questionOrder,
              width: 50,

              align: "center",
              customRender: (text, record) =>
                record.questions[index].actualScore || "",
            })),
            {
              dataIndex: "studentType",
              title: "分类",
              align: "center",
              width: 85,
            },
            {
              dataIndex: "categoryUnionRank",
              title: "整体分类排名",
              align: "center",
              width: 110,
            },
            {
              dataIndex: "categorySchoolRank",
              title: "校内分类排名",
              align: "center",
              width: 110,
            },
            {
              dataIndex: "note",
              title: "备注",
              width: 100,

              align: "center",
            },
          ];
          if (this.statInfo.isUnionDisplayStudentRank == 1) {
            this.columns.splice(
              0,
              0,
              {
                dataIndex: "unionRank",
                title: "整体排名",
                width: 75,
                align: "center",
              },
              {
                dataIndex: "schoolRank",
                title: "校内排名",
                width: 75,

                align: "center",
              }
            );
          }
        }
        this.tableData = res.data.data.records;
        this.total = res.data.data.total;
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    dataTypeChange() {
      this.search.current = 1;
      this.tabsChange();
    },
    getData() {
      if (this.subjectId === 0) {
        if (this.type === "1") {
          this.getTotalKey1Data();
        } else {
          this.getTotalDetailKey1Data();
        }
      } else {
        if (this.type == "1") {
          this.getSubjectKey1Data();
        } else {
          this.getSubjectDetailKey1Data();
        }
      }
    },
    tabsChange() {
      if (this.subjectId !== null) {
        this.search.current = 1;
        this.getData();
      }
    },
    scoreClick(subjectId, record, answerSheetUrl) {
      if (answerSheetUrl) {
        sessionStorage.setItem("imgList", answerSheetUrl);
        const { href } = this.$router.resolve({
          path: "/academic/card",
          query: {
            name: record.name,
            studentId: record.studentId,
            statId: this.$route.query.id,
            subjectId: subjectId,
          },
        });
        window.open(href, "_blank");
      } else {
        this.$message.error("无可查看答题卡");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  .operate {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;

    .title-content {
      display: flex;
      align-items: center;

      .title {
        font-size: 14px;
        color: #0a1119;
        font-weight: 600;
        margin-right: 8px;
      }
    }
  }
}

::v-deep .ant-tabs-bar {
  margin: 0 0 0 0 !important;
}

.line-chart-title {
  margin: 18px 0;
  color: #0a1119;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.charts-box {
  width: 100%;

  .charts {
    width: 100%;
    height: 650px;
  }
}

.pointScore {
  cursor: pointer;
  color: #2474ed;
}

.row {
  margin-bottom: 18px;
}

::v-deep .ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 4px 6px !important;
}

::v-deep .ant-table-row-cell-break-word {
  padding: 4px 6px !important;
}

::v-deep .ant-table-pagination.ant-pagination {
  float: right;
  margin: 10px 0;
}

.progress {
  width: 100%;
  margin: 18px 0;

  .text {
    color: #0a1119;
    font-size: 14px;
    margin-top: 8px;

    .ant-btn-link {
      padding: 0 !important;
      text-decoration: underline !important;
    }
  }
}
</style>
