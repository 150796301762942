var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"operate"},[_c('div',{staticClass:"title-content"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$route.query.name)+"-"+_vm._s(_vm.subjectId === 0 ? "总分" : _vm.subjectName)+_vm._s(_vm.type == "2" ? "详细" : "")+"成绩榜 ")]),_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v("采用"+_vm._s(_vm.dataType === 2 ? "赋分" : "原始分")+"数据源统计")])]),(
            _vm.scoreType == 1 &&
            _vm.$route.query.ruleId > 0 &&
            ![1, 2, 3, 4, 8].includes(_vm.subjectId)
          )?_c('a-radio-group',{attrs:{"button-style":"solid","size":"small"},on:{"change":_vm.dataTypeChange},model:{value:(_vm.dataType),callback:function ($$v) {_vm.dataType=$$v},expression:"dataType"}},[_c('a-radio-button',{attrs:{"value":1}},[_vm._v(" 原始分 ")]),_c('a-radio-button',{attrs:{"value":2}},[_vm._v(" 赋分 ")])],1):_vm._e()],2)],1),_c('div',{staticClass:"btns"},[_c('a-space',[_c('a-input',{attrs:{"placeholder":"搜索考号或姓名"},on:{"input":_vm.getData},model:{value:(_vm.search.input),callback:function ($$v) {_vm.$set(_vm.search, "input", $$v)},expression:"search.input"}}),(_vm.type === '1')?_c('a-button',{attrs:{"type":"primary","ghost":"","loading":_vm.btnLoading},on:{"click":_vm.downloadFile}},[_vm._v("下载"+_vm._s(_vm.subjectName)+"成绩榜")]):_vm._e(),(_vm.type === '2')?_c('a-button',{attrs:{"type":"primary","ghost":"","loading":_vm.btnLoading},on:{"click":_vm.downloadFile}},[_vm._v("下载"+_vm._s(_vm.subjectName)+"详细成绩榜")]):_vm._e(),_c('a-button',{attrs:{"type":"primary","ghost":""},on:{"click":_vm.exportSheetCard}},[_vm._v("导出答题卡")])],1)],1)]),_c('subjectList',{ref:"subject",attrs:{"has-total":""},on:{"change":_vm.subjectChange},model:{value:(_vm.subjectId),callback:function ($$v) {_vm.subjectId=$$v},expression:"subjectId"}}),_c('a-tabs',{attrs:{"type":"card"},on:{"change":_vm.tabsChange},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},[_c('a-tab-pane',{key:"1",attrs:{"tab":"成绩榜"}}),_c('a-tab-pane',{key:"2",attrs:{"tab":"详细成绩榜"}})],1),_c('a-table',{attrs:{"bordered":"","row-key":(record) => record.id,"columns":_vm.columns,"data-source":_vm.tableData,"pagination":{
      total: _vm.total,
      pageSize: _vm.search.size,
      current: _vm.search.current,
      showSizeChanger: true,
      showTotal: (total) => `共 ${total}条`,
    },"scroll":{ x: 'max-content', y: _vm.tableX }},on:{"change":_vm.tablePaginationChange}}),_c('a-modal',{attrs:{"title":`导出${_vm.subjectName}答题卡`,"width":"35%","footer":_vm.showProgress ? null : undefined,"ok-text":_vm.progressNum >= 100 ? '重新生成并导出' : '生成并导出'},on:{"ok":_vm.handleOk,"cancle":_vm.handleCancle},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('div',{staticClass:"row"},[_vm._v(" 分数 "),_c('a-input',{staticStyle:{"width":"80px"},attrs:{"disabled":_vm.showProgress},model:{value:(_vm.score),callback:function ($$v) {_vm.score=_vm._n($$v)},expression:"score"}})],1),_c('a-radio-group',{attrs:{"disabled":_vm.showProgress},model:{value:(_vm.scoreTypes),callback:function ($$v) {_vm.scoreTypes=$$v},expression:"scoreTypes"}},[_c('a-radio',{attrs:{"value":1}},[_vm._v(" 导出低分答题卡（小于等于≤，>0） ")]),_c('a-radio',{attrs:{"value":2}},[_vm._v(" 导出高分答题卡（大于>） ")])],1),(_vm.progressNum > -1)?_c('div',{staticClass:"progress"},[_c('a-progress',{attrs:{"stroke-linecap":"square","stroke-color":"#2474ED","percent":_vm.progressNum}}),(_vm.progressNum < 100)?_c('div',{staticClass:"text"},[_vm._v(" 数据生成中，请耐心等待，或稍后回到此页面进行导出操作。 ")]):_c('div',{staticClass:"text"},[_vm._v(" 数据已生成，若导出尚未启动，可点击此处 "),_c('a-button',{attrs:{"type":"link","loading":_vm.btnLoading},on:{"click":_vm.downloadCard}},[_vm._v("手动下载")])],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }